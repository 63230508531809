import { useContext } from "react";
import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../stores/rootStore";
import { HOME_URL } from "../constant/routes";

interface IProps {
  component: JSX.Element;
}

const ProtectedRoute: React.FC<IProps> = ({ component }) => {
  const rootStore = useContext(RootStoreContext);
  const { isLoggedIn, setRedirectUrl } = rootStore.userStore;
  let location = useLocation();

  if (!isLoggedIn) {
    setRedirectUrl(
      //@ts-ignore
      `${location.pathname}${location.search}`,
    );

    return <Navigate to={HOME_URL} replace />;
  } else return component;
};

export default observer(ProtectedRoute);
