import { NotFound } from "@intouchhealth/cig-components";
import { Route } from "react-router-dom";
import { microsites } from "../../config/microsites";
import { Loader } from "../../features/_components/Loader";
import { UserNotAuthorized } from "../constant/errorMessages";
import { loadingMessage } from "../constant/loadingMessages";
import MicroFrontend from "../microfrontend/Microfrontend";
import { IMicrosite } from "../models/microsite";
import ProtectedRoute from "./ProtectedRoute";

export const availableMicrosites = (
  userApplications: IMicrosite[],
  isUserApplicationsLoading: boolean,
) =>
  microsites.map((microsite) => (
    <Route
      key={`${microsite.name}-route`}
      path={`${microsite.entrypoint}/*`}
      element={
        <ProtectedRoute
          component={
            userApplications.find(
              (allowedApplication: IMicrosite) =>
                allowedApplication?.displayName === microsite.displayName,
            ) ? (
              <MicroFrontend
                host={microsite.host}
                name={microsite.name}
                window={window}
                document={document}
              />
            ) : isUserApplicationsLoading ? (
              <Loader message={loadingMessage} />
            ) : (
              <NotFound errorData={UserNotAuthorized} />
            )
          }
        />
      }
    />
  ));
