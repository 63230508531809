import { RootStore } from "./rootStore";
import { observable, action, makeObservable } from "mobx";

export default class CommonStore {
  rootStore: RootStore;
  appLoaded = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      appLoaded: observable,
      setAppLoaded: action,
    });
  }

  setAppLoaded = () => (this.appLoaded = true);
}
