import { createRoot } from "react-dom/client";
import App from "./app/layout/App";
import "@intouchhealth/cig-components/build/index.css";
import { StrictMode } from "react";

const container = document.getElementById("root");

if (container !== null) {
  const root = createRoot(container);

  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
