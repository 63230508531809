import { useEffect } from "react";
import * as React from "react";
import { history } from "../constant/history";
import { RootStoreContext } from "../stores/rootStore";
import { useNavigate } from "react-router-dom";
import { NOT_FOUND_URL } from "../constant/routes";
import { Loader } from "../../features/_components/Loader";
import { loadingMessage } from "../constant/loadingMessages";

export type IProps = {
  host: string;
  name: string;
  window: Window;
  document: Document;
};

const MicroFrontend: React.FC<IProps> = ({ window, document, name, host }) => {
  const rootStore = React.useContext(RootStoreContext);
  const { setLoading } = rootStore.microFrontStore;
  let navigate = useNavigate();
  const [fetchingAssets, setFetchingAssets] = React.useState<boolean>(true);

  useEffect(() => {
    const scriptId = `micro-frontend-script-${name}`;
    let link: HTMLLinkElement;
    let script: HTMLScriptElement;

    if (!rootStore.microFrontStore.loading) {
      setLoading(true);

      if (document.getElementById(scriptId)) {
        renderMicroFrontend();
        return;
      }

      setFetchingAssets(false);
      fetch(`${host}/asset-manifest.json`)
        .then((res) => res.json())
        .then((manifest) => {
          setFetchingAssets(true);
          script = document.createElement("script");
          script.id = scriptId;
          script.crossOrigin = "";
          script.src =
            (process.env.REACT_APP_ENV === "dev" ? host : "") +
            manifest.files["main.js"];
          script.onload = renderMicroFrontend;
          document.head.appendChild(script);
          if (manifest.files["main.css"]) {
            link = document.createElement("link");
            link.href =
              (process.env.REACT_APP_ENV === "dev" ? host : "") +
              manifest.files["main.css"];
            link.rel = "stylesheet";
            document.head.appendChild(link);
          }
        })
        .catch(() => {
          navigate(NOT_FOUND_URL);
          setLoading(false);
        });
    }

    return () => {
      try {
        document.getElementById(scriptId)?.remove();
        document.head.removeChild(link);
        document.head.removeChild(script);

        //@ts-ignore
        window[`unmount${name}`](`${name}-container`);
      } catch {}
    };
  }, [name]); //eslint-disable-line react-hooks/exhaustive-deps

  const renderMicroFrontend = () => {
    try {
      //@ts-ignore
      window[`render${name}`](`${name}-container`, history);
    } catch {
      navigate(NOT_FOUND_URL);
    }
    setLoading(false);
  };

  if (!fetchingAssets) return <Loader message={loadingMessage} />;

  return (
    <main id={`${name}-container`} style={{ display: "flex", flex: 1 }}>
      <Loader message={loadingMessage} />
    </main>
  );
};

MicroFrontend.defaultProps = {
  document,
  window,
};

export default MicroFrontend;
