import { AxiosInstance, AxiosResponse } from "axios";
import { User } from "@intouchhealth/oidc-client";
import Logger from "../utils/logger";

const userSessionItem = `oidc.user:${process.env.REACT_APP_OIDC_AUTHORITY}:${process.env.REACT_APP_OIDC_CLIENT_ID}`;

export function configureInstance(axiosInstance: AxiosInstance) {
  /* Authentication header configuration */
  axiosInstance.interceptors.request.use(
    (config) => {
      const storedUser: User = JSON.parse(
        window.sessionStorage.getItem(userSessionItem)!,
      );

      if (storedUser.access_token && config?.headers)
        config.headers.Authorization = `Bearer ${storedUser.access_token}`;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  /* Response handler configuration */
  axiosInstance.interceptors.response.use(undefined, (error) => {
    const { status } = error.response || 500;

    if (status === 500) Logger(["An unexpected error has occurred"]);

    throw error.response || { status };
  });
}

export function configureHttpRequestsWrapper(axiosInstance: AxiosInstance) {
  const responseBody = (response: AxiosResponse) => response.data;

  return {
    get: (url: string) => axiosInstance.get(url).then(responseBody),
  };
}
