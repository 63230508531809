import {
  observable,
  action,
  runInAction,
  configure,
  makeObservable,
} from "mobx";
import { RootStore } from "./rootStore";
import Logger from "../utils/logger";

configure({ enforceActions: "always", isolateGlobalState: true });

export default class MicroFrontStore {
  rootStore: RootStore;
  loading: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      setLoading: action,
      loading: observable,
    });
  }

  setLoading = (loading: boolean) => {
    try {
      runInAction(() => (this.loading = loading));
    } catch (error) {
      Logger(["Error while setting loading", error]);
    }
  };
}
