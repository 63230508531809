import { LogoutIcon, RoutineIcon } from "@intouchhealth/cig-components";
import { ItemValueEnum } from "../../../app/models/enum/itemValueEnum";

export const userDropdownItems: Item[] = [
  {
    value: ItemValueEnum.Theme,
    name: "Change theme",
    icon: <RoutineIcon />,
  },
  {
    value: ItemValueEnum.Logout,
    name: "Logout",
    icon: <LogoutIcon />,
  },
];

export type Item = {
  name: string;
  value: number;
  icon: JSX.Element;
};
