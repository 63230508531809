import { useEffect } from "react";
import * as React from "react";
import { history } from "../constant/history";
import { RootStoreContext } from "../stores/rootStore";
import { useNavigate } from "react-router-dom";
import { NOT_FOUND_URL } from "../constant/routes";
import { Loader } from "../../features/_components/Loader";
import { loadingMessage } from "../constant/loadingMessages";

export type IProps = {
  host: string;
  name: string;
  window: Window;
  document: Document;
};

const MicroFrontend: React.FC<IProps> = ({ window, document, name, host }) => {
  const rootStore = React.useContext(RootStoreContext);
  const { setLoading } = rootStore.microFrontStore;
  let navigate = useNavigate();

  useEffect(() => {
    const scriptId = `micro-frontend-script-${name}`;
    let link: HTMLLinkElement;
    let script: HTMLScriptElement;

    if (!rootStore.microFrontStore.loading) {
      setLoading(true);

      if (document.getElementById(scriptId)) {
        renderMicroFrontend();
        return;
      }
      script = document.createElement("script");
      script.id = scriptId;
      script.type = "module";
      script.crossOrigin = "";
      script.src = "http://localhost:4174/src/index.tsx";
      script.onload = renderMicroFrontend;
      document.head.appendChild(script);
    }

    return () => {
      try {
        document.getElementById(scriptId)?.remove();
        document.head.removeChild(link);
        document.head.removeChild(script);

        //@ts-ignore
        window[`unmount${name}`](`${name}-container`);
      } catch {}
    };
  }, [name]); //eslint-disable-line react-hooks/exhaustive-deps

  const renderMicroFrontend = () => {
    try {
      //@ts-ignore
      window[`render${name}`](`${name}-container`, history);
    } catch (e) {
      console.log(e);
      navigate(NOT_FOUND_URL);
    }
    setLoading(false);
  };

  return (
    <main id={`${name}-container`} style={{ display: "flex", flex: 1 }}>
      <Loader message={loadingMessage} />
    </main>
  );
};

MicroFrontend.defaultProps = {
  document,
  window,
};

export default MicroFrontend;
