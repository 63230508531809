import {
  observable,
  action,
  runInAction,
  configure,
  makeObservable,
} from "mobx";
import { RootStore } from "./rootStore";
import Logger from "../utils/logger";
import { IMicrosite } from "../models/microsite";
import accessControlResources from "../api/accessControl";
import { microsites } from "../../config/microsites";

configure({ enforceActions: "always", isolateGlobalState: true });

export default class ApplicationStore {
  rootStore: RootStore;
  userApplications: IMicrosite[] = [];
  isUserApplicationsLoading: boolean = true;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      getUserApplications: action,
      userApplications: observable,
      isUserApplicationsLoading: observable,
    });
  }

  getUserApplications = async () => {
    try {
      const userApplications =
        await accessControlResources.Applications.request();

      let userApps: IMicrosite[] = [];
      if (userApplications !== null && userApplications.length > 0) {
        userApplications.forEach(({ displayName }) => {
          const matchMicrosite = microsites.find(
            (allowedApplication: IMicrosite) =>
              allowedApplication?.displayName === displayName,
          );
          if (matchMicrosite !== undefined) userApps.push(matchMicrosite);
        });
      }

      runInAction(() => {
        this.userApplications = userApps;
        this.isUserApplicationsLoading = false;
      });
    } catch (error) {
      Logger(["Error while setting loading", error]);
    }
  };
}
