import { useContext, useEffect } from "react";
import { RootStoreContext } from "../../app/stores/rootStore";

const SilentRenew = () => {
  const rootStore = useContext(RootStoreContext);
  const { silentRenew } = rootStore.userStore;

  useEffect(() => {
    (async function renewToken() {
      await silentRenew();
    })();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default SilentRenew;
