export class OidcConfiguration {
  authority: string = "";
  redirect_uri: string = "";
  post_logout_redirect_uri: string = "";
  silent_redirect_uri: string = "";
  client_id: string = "";
  response_type: string = "";
  scope: string = "";
  automaticSilentRenew: boolean = false;
  includeIdTokenInSilentRenew: boolean = false;
  accessTokenExpiringNotificationTime: number = -1;
  loadUserInfo: boolean = false;

  constructor() {
    try {
      this.authority = process.env.REACT_APP_OIDC_AUTHORITY!;
      this.redirect_uri = process.env.REACT_APP_OIDC_REDIRECT_URI!;
      this.post_logout_redirect_uri =
        process.env.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI!;
      this.silent_redirect_uri =
        process.env.REACT_APP_OIDC_SILENT_REDIRECT_URI!;
      this.client_id = process.env.REACT_APP_OIDC_CLIENT_ID!;
      this.response_type = process.env.REACT_APP_OIDC_RESPONSE_TYPE!;
      this.scope = process.env.REACT_APP_OIDC_SCOPE!;
      this.automaticSilentRenew =
        process.env.REACT_APP_OIDC_AUTOMATIC_SILENT_RENEW! === "true"
          ? true
          : false;
      this.includeIdTokenInSilentRenew =
        process.env.REACT_APP_OIDC_INCLUDE_ID_TOKEN_IN_SILENT_RENEW! === "true"
          ? true
          : false;
      this.accessTokenExpiringNotificationTime = Number(
        process.env.REACT_APP_OIDC_ACCESS_TOKEN_EXPIRING_NOTIFICATION_TIME!,
      );
      this.loadUserInfo =
        process.env.REACT_APP_OIDC_LOAD_USER_INFO! === "true" ? true : false;
    } catch (error) {}
  }
}
