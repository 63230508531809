import { Dimmer, LoaderIcon } from "@intouchhealth/cig-components";
import React from "react";

export const Loader: React.FC<IProps> = React.memo(({ message }) => (
  <Dimmer
    fullScreen
    header={message}
    icon={<LoaderIcon iconSize="xxl" sizing="relative" />}
  />
));

interface IProps {
  message: string;
}
