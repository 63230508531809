import { IMicrosite } from "../models/microsite";

export const getMicrositesMap = (applications: IMicrosite[]) => {
  const micrositeMap: { [key: string]: number } = {};

  applications.map(
    (microsite: IMicrosite, key: number) =>
      (micrositeMap[microsite.entrypoint] = key),
  );

  return micrositeMap;
};
