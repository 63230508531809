export const PageNotFound = {
  errorTitle: "Page not found",
  errorMessage:
    "Review that the URL has been typed correctly. \nIf the problem persists, contact FleetOps or TAC for assistance or to report the issue.",
  image: "/assets/404Page.png",
};

export const UserNotAuthorized = {
  errorTitle: "User not authorized",
  errorMessage:
    "It looks like you do not have permission to access this application. If you believe you should have access, please contact your administrator.",
  image: "/assets/unauthorized.png",
};
