import { useContext } from "react";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../app/stores/rootStore";
import { mui, muiStyles, Menu } from "@intouchhealth/cig-components";
import { UserItem } from "./_components/UserItem";
import "./styles/menu.css";
import { micrositesItems, Item } from "./utils/micrositesItems";
import { useNavigate } from "react-router-dom";
import LogoItem from "./_components/LogoItem";

const { styled } = muiStyles;
const { Toolbar, AppBar, Grid } = mui;

const NavBar: React.FC<IProps> = ({ selectedValue }) => {
  let navigate = useNavigate();

  const rootStore = useContext(RootStoreContext);
  const { userApplications } = rootStore.applicationStore;
  const { loading } = rootStore.microFrontStore;
  const { isLoggedIn, user } = rootStore.userStore;

  const menuItems: Item[] = micrositesItems(userApplications);

  const onClick = (itemValue: number) => {
    navigate(menuItems[itemValue].entrypoint);
  };

  return (
    <StyledAppBar position="fixed">
      <StyledToolbar>
        <Grid container>
          <GridItem item xs={3} md={2} xl={1.5}>
            <LogoItem />
          </GridItem>
          <GridPadding item xs={3} md={4} xl={6.5}>
            <Menu
              title="Services"
              items={menuItems}
              onClick={onClick}
              selectedValue={selectedValue}
              disabled={loading || menuItems.length === 0}
            />
          </GridPadding>
          {isLoggedIn && user && (
            <GridContainer item xs={6} xl={4}>
              <UserItem username={user.profile.preferred_username} />
            </GridContainer>
          )}
        </Grid>
      </StyledToolbar>
    </StyledAppBar>
  );
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: `${theme.palette.background.paper} !important`,
  margin: "0 !important",
  borderRadius: "0 !important",
  minHeight: "4rem !important",
  maxHeight: "4rem !important",
  boxShadow: "0 1px 2px 0 rgb(34 36 38 / 15%) !important",
  border: "1px solid rgba(34, 36, 38, 0.15) !important",
  zIndex: 1,
}));
const GridContainer = styled(Grid)({
  alignItems: "center",
  justifyContent: "end",
  display: "flex",
});
const GridItem = styled(Grid)({
  display: "flex",
});
const GridPadding = styled(Grid)({
  padding: "8px",
});
const StyledToolbar = styled(Toolbar)({
  minHeight: "inherit",
});

interface IProps {
  selectedValue: number | string;
}

export default observer(NavBar);
