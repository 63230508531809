import { configure } from "mobx";
import { createContext } from "react";
import UserStore from "./userStore";
import CommonStore from "./commonStore";
import MicroFrontStore from "./microFrontStore";
import ApplicationStore from "./applicationStore";

configure({ enforceActions: "always", isolateGlobalState: true });

export class RootStore {
  applicationStore: ApplicationStore;
  userStore: UserStore;
  commonStore: CommonStore;
  microFrontStore: MicroFrontStore;

  constructor() {
    this.applicationStore = new ApplicationStore(this);
    this.userStore = new UserStore(this);
    this.commonStore = new CommonStore(this);
    this.microFrontStore = new MicroFrontStore(this);
  }
}

export const RootStoreContext = createContext(new RootStore());
