import { Menu } from "@intouchhealth/cig-components";
import * as React from "react";
import { useContext } from "react";
import { themeMode } from "../../../app/constant/constants";
import { ItemValueEnum } from "../../../app/models/enum/itemValueEnum";
import { ThemeEnum } from "../../../app/models/enum/themeEnum";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { userDropdownItems } from "../utils/userDropdownItems";

export const UserItem: React.FC<IProps> = ({ username }) => {
  const rootStore = useContext(RootStoreContext);
  const { logout } = rootStore.userStore;

  const handleOnClick = (itemValue: number) => {
    if (itemValue === ItemValueEnum.Theme) {
      const theme = window.localStorage.getItem(themeMode);
      if (theme === ThemeEnum.Light)
        window.localStorage.setItem(themeMode, ThemeEnum.Dark);
      else window.localStorage.setItem(themeMode, ThemeEnum.Light);
      window.location.reload();
    } else logout();
  };

  return (
    <Menu
      title={username}
      items={userDropdownItems}
      onClick={handleOnClick}
      selectedValue=""
    />
  );
};

interface IProps {
  username: string | undefined;
}
