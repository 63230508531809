import {
  observable,
  computed,
  action,
  runInAction,
  configure,
  makeObservable,
} from "mobx";
import { UserManager, User } from "@intouchhealth/oidc-client";
import { RootStore } from "./rootStore";
import { OidcConfiguration } from "../models/oidc";
import Logger from "../utils/logger";
import { Nullable } from "../models/nullable";
import { DASHBOARD_URL } from "../constant/routes";
import { ThemeEnum } from "../models/enum/themeEnum";

configure({ enforceActions: "always", isolateGlobalState: true });

export default class UserStore {
  private readonly configuration: OidcConfiguration;
  private readonly manager: UserManager;
  readonly rootStore: RootStore;
  user: Nullable<User> = null;
  redirectUrl = window.sessionStorage.getItem("redirectPath") ?? DASHBOARD_URL;

  constructor(rootStore: RootStore) {
    this.configuration = new OidcConfiguration();
    this.manager = new UserManager(this.configuration);
    this.rootStore = rootStore;
    this.manager.events.addAccessTokenExpiring(this.silentLogin);
    this.manager.events.addAccessTokenExpired(this.silentLogin);

    makeObservable(this, {
      completeLogin: action,
      completeLogout: action,
      isLoggedIn: computed,
      loadUser: action,
      login: action,
      logout: action,
      redirectUrl: observable,
      setRedirectUrl: action,
      silentLogin: action,
      silentRenew: action,
      user: observable,
    });
  }

  completeLogin = async () => {
    try {
      const user = await this.manager.signinRedirectCallback();
      window.localStorage.setItem("logout", "false");

      runInAction(() => (this.user = user));
    } catch (error) {
      Logger(["Error while completing login", error]);
    }
  };

  completeLogout = async () => {
    try {
      await this.manager.signoutRedirectCallback();
      await this.manager.removeUser();
      runInAction(() => (this.user = null));
    } catch (error) {
      Logger(["Error while completing logout", error]);
      runInAction(() => (this.user = null));
    }
  };

  get isLoggedIn() {
    return this.user != null && this.user.access_token && !this.user.expired;
  }

  loadUser = async () => {
    try {
      const user = await this.manager.getUser();

      runInAction(() => (this.user = user));
    } catch (error) {
      Logger(["Error while loading user", error]);
    }
  };

  login = async () => {
    try {
      await this.manager.signinRedirect({
        extraQueryParams: {
          theme: ThemeEnum.Light,
        },
      });
    } catch (error) {
      Logger(["Error while performing login", error]);
    }
  };

  logout = async () => {
    try {
      await this.manager.signoutRedirect();
      window.localStorage.setItem("logout", "true");
    } catch (error) {
      Logger(["Error while performing logout", error]);
    }
  };

  setRedirectUrl = (url: string) => {
    this.redirectUrl = url;
    window.sessionStorage.setItem("redirectPath", url)!;
  };

  silentLogin = async () => {
    try {
      const user = await this.manager.signinSilent({
        scope: this.configuration.scope,
        response_type: this.configuration.response_type,
      });

      runInAction(() => (this.user = user));
    } catch (error) {
      // Handle IFrame window timeout error
      Logger([error]);

      const user = await this.manager.getUser();
      runInAction(() => (this.user = user));
    }
  };

  silentRenew = async () => {
    try {
      await this.manager.signinSilentCallback();
    } catch (error) {
      Logger([error]);
    }
  };
}
