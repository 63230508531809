import { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../app/stores/rootStore";
import { useNavigate } from "react-router-dom";
import { loggingOffMessage } from "../../app/constant/loadingMessages";
import { HOME_URL } from "../../app/constant/routes";
import { Loader } from "../_components/Loader";

const LogoutPage = () => {
  const rootStore = useContext(RootStoreContext);
  const { completeLogout } = rootStore.userStore;
  let navigate = useNavigate();

  useEffect(() => {
    completeLogout();
    navigate(HOME_URL);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return <Loader message={loggingOffMessage} />;
};

export default observer(LogoutPage);
