import * as React from "react";
import {
  Typography,
  mui,
  muiStyles,
  Card,
  MicrositeIcon,
} from "@intouchhealth/cig-components";
import { IMicrosite } from "../../app/models/microsite";
import { useNavigate } from "react-router-dom";
import MicrositeCard from "./MicrositeCard";

const { Grid } = mui;
const { styled } = muiStyles;

const DashboardGridCard: React.FC<IProps> = ({ userApplications }) => {
  let navigate = useNavigate();

  return (
    <Grid item xs={9} xl={7}>
      <Card isHoverable={false}>
        <Grid container spacing={2}>
          {userApplications.length > 0 ? (
            userApplications.map((loggedInUserApplication: IMicrosite) => (
              <MicrositeCard
                onClick={() => {
                  navigate(loggedInUserApplication.entrypoint);
                }}
                name={loggedInUserApplication.displayName}
                icon={
                  <MicrositeIcon
                    name={loggedInUserApplication.name}
                    iconSize="xl"
                  />
                }
                key={`${loggedInUserApplication.name}-card`}
              />
            ))
          ) : (
            <NoContentItem item xs={12}>
              <Typography type="h3">
                You have no enabled applications.
              </Typography>
            </NoContentItem>
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

export default React.memo(DashboardGridCard);

const NoContentItem = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "8rem",
});

interface IProps {
  userApplications: IMicrosite[];
}
