import {
  LogoDarkModeIcon,
  LogoIcon,
  Typography,
} from "@intouchhealth/cig-components";
import { observer } from "mobx-react-lite";
import React from "react";
import { useContext } from "react";
import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { themeMode } from "../../../app/constant/constants";
import { DASHBOARD_URL } from "../../../app/constant/routes";
import { ThemeEnum } from "../../../app/models/enum/themeEnum";
import { RootStoreContext } from "../../../app/stores/rootStore";

const LogoItem: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loading } = rootStore.microFrontStore;

  return (
    <Item end to={DASHBOARD_URL} disabled={loading}>
      <Icon />
      {!isMobile && (
        <Typography type="h4" fontWeight="bold">
          HHS Console
        </Typography>
      )}
    </Item>
  );
};

const Icon = () =>
  window.localStorage.getItem(themeMode) === ThemeEnum.Dark ? (
    <LogoDarkModeIcon iconSize="m" aria-label="logo-dark-mode-icon" />
  ) : (
    <LogoIcon iconSize="m" aria-label="logo-icon" />
  );

const Item = styled(NavLink)<ItemProps>`
  display: flex;
  margin-right: 0 !important;
  align-items: center;
  text-decoration: none;
  ${({ disabled }) =>
    disabled
      ? `pointer-events: none;
     cursor: default;`
      : `cursor: pointer;
     pointer-events: auto;`};

  span {
    margin-left: 10px;
  }
`;

interface ItemProps {
  disabled: boolean;
}

export default observer(LogoItem);
