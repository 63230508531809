import { useContext, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../app/stores/rootStore";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { signingYouInMessage } from "../../app/constant/loadingMessages";
import { Loader } from "../_components/Loader";

const LoginPage = () => {
  const rootStore = useContext(RootStoreContext);
  const { completeLogin, redirectUrl } = rootStore.userStore;
  const didLogRef = useRef(false);
  let navigate = useNavigate();

  useEffect(() => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      completeLogin().then(() => {
        navigate(redirectUrl);
      });
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Loader message={signingYouInMessage} />
    </Container>
  );
};

const Container = styled.div`
  z-index: 10000;
  width: 100%;
`;

export default observer(LoginPage);
