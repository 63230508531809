import { createGlobalStyle } from "styled-components/macro";
import { colors, mediumFont } from "@intouchhealth/cig-components";

const GlobalStyle = createGlobalStyle`
  a {
    text-decoration: none;
  }

  body {
    background-color: rgb(234, 234, 234);
    display: flex;
    margin: 0;
    min-height: 100%;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      background: #${colors.N50};
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #${colors.N300};
      border-radius: 10px;
    }
  }

  html {
    height: 100%;
  }

  #root {
    display: flex;
    flex-flow: column;
    width: 100%;
  }
  
  div {
    ${mediumFont}
  }
`;

export default GlobalStyle;
