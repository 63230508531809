import * as React from "react";
import { Typography, mui, muiStyles } from "@intouchhealth/cig-components";

const { Grid } = mui;
const { styled } = muiStyles;

export const DashboardTitle: React.FC<IProps> = ({ firstName, lastName }) => (
  <GridItem item xs={12}>
    <Typography type="h1">
      Welcome {firstName} {lastName}
    </Typography>
  </GridItem>
);

const GridItem = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  paddingBottom: "4rem",
  padding: "1rem",
});

interface IProps {
  firstName: string;
  lastName: string;
}
