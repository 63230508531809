import axios from "axios";
import { IUserAccessApplication } from "../models/userAccessApplication";
import {
  configureHttpRequestsWrapper,
  configureInstance,
} from "./configureAgent";

const accessControlInstance = axios.create({
  baseURL: process.env.REACT_APP_ACCESS_CONTROL_API_URL,
});

configureInstance(accessControlInstance);

const requests = configureHttpRequestsWrapper(accessControlInstance);

const Applications = {
  request: (): Promise<IUserAccessApplication[]> => requests.get("/apps"),
};

const accessControlResources = {
  Applications,
};

export default accessControlResources;
