import { useContext, useEffect } from "react";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../app/stores/rootStore";
import { mui, muiStyles } from "@intouchhealth/cig-components";
import { HHSTitle } from "../../app/constant/titles";
import { Loader } from "../_components/Loader";
import { loadingMessage } from "../../app/constant/loadingMessages";
import { DashboardTitle } from "../_components/DashboardTitle";
import DashboardGridCard from "../_components/DashboardGridCard";

const { Grid } = mui;
const { styled } = muiStyles;

const Dashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.userStore;
  const { userApplications, isUserApplicationsLoading } =
    rootStore.applicationStore;

  useEffect(() => {
    document.title = HHSTitle;
  }, []);

  if (!user || isUserApplicationsLoading)
    return <Loader message={loadingMessage} />;

  return (
    <GridContainer container>
      <DashboardTitle
        firstName={user!.profile.given_name!}
        lastName={user!.profile.family_name!}
      />
      <DashboardGridCard userApplications={userApplications} />
    </GridContainer>
  );
};

const GridContainer = styled(Grid)({
  padding: "1rem",
  paddingTop: "3rem",
  justifyContent: "center",
  height: "fit-content",
});

export default observer(Dashboard);
