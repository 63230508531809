import {
  Card,
  Typography,
  mui,
  muiStyles,
} from "@intouchhealth/cig-components";

const { Grid, useTheme } = mui;
const { styled } = muiStyles;

const MicrositeCard: React.FC<IProps> = ({ icon, name, onClick }) => {
  const palette = useTheme().palette;

  return (
    <GridContainer item xs={12} md={6} lg={4} onClick={onClick}>
      <Card
        style={{
          border: `2px solid ${palette.text.primary}`,
          height: "100%",
        }}
        isHoverable={false}
      >
        <GridInternalContainer container spacing={1}>
          <GridIcon item xs={12}>
            {icon}
          </GridIcon>

          <GridItem item xs={12}>
            <Typography type="h3">{name}</Typography>
          </GridItem>
        </GridInternalContainer>
      </Card>
    </GridContainer>
  );
};

const GridContainer = styled(Grid)({
  height: "9rem",
  cursor: "pointer",
});
const GridIcon = styled(Grid)({
  display: "flex",
  justifyContent: "center",
});
const GridInternalContainer = styled(Grid)({
  height: "7rem",
});
const GridItem = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
});

interface IProps {
  name: string;
  icon: JSX.Element;
  onClick: () => void;
}

export default MicrositeCard;
