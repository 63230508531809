import { MicrositeIcon } from "@intouchhealth/cig-components";
import { IMicrosite } from "../../../app/models/microsite";

export const micrositesItems = (loggedInUserApplications: IMicrosite[]) => {
  const items: Item[] = loggedInUserApplications.map((microsite, key) => {
    return {
      value: key,
      name: microsite.displayName,
      icon: <MicrositeIcon name={microsite.name} />,
      entrypoint: microsite.entrypoint,
    };
  });

  return items;
};

export type Item = {
  name: string;
  value: number;
  icon: JSX.Element;
  entrypoint: string;
};
