import { useContext, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../stores/rootStore";
import GlobalStyle from "../../globalStyles";
import {
  darkTheme,
  InjectFont,
  lightTheme,
  mui,
} from "@intouchhealth/cig-components";
import HhsRoutes from "./HhsRoutes";
import { BrowserRouter } from "react-router-dom";
import { ThemeEnum } from "../models/enum/themeEnum";
import { themeMode } from "../constant/constants";

const { createTheme, ThemeProvider, StyledEngineProvider } = mui;

const App = () => {
  const rootStore = useContext(RootStoreContext);
  const { setAppLoaded } = rootStore.commonStore;
  const { loadUser, isLoggedIn } = rootStore.userStore;
  const didLogRef = useRef(false);

  const getTheme = () => {
    if (window.localStorage.getItem(themeMode) === null)
      window.localStorage.setItem(themeMode, ThemeEnum.Light);

    if (window.localStorage.getItem(themeMode) === ThemeEnum.Dark)
      return createTheme(darkTheme);
    else return createTheme(lightTheme);
  };

  const checkUser = () => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      (async function loadApp() {
        if (!isLoggedIn) await loadUser();
        setAppLoaded();
      })();
    }
  };

  useEffect(checkUser, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getTheme()}>
        <InjectFont family="Montserrat" />
        <GlobalStyle />
        <BrowserRouter>
          <HhsRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default observer(App);
