import { useContext, useEffect, useRef } from "react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { redirectingToLoginMessage } from "../../app/constant/loadingMessages";
import { Loader } from "../_components/Loader";

const LandingPage = () => {
  const rootStore = useContext(RootStoreContext);
  const { login } = rootStore.userStore;
  const didLogRef = useRef(false);

  useEffect(() => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      login();
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return <Loader message={redirectingToLoginMessage} />;
};

export default LandingPage;
